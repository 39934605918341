.spacingSubtitle {
   margin-top: var(--spacing-1x);
}

.spacingInput {
   margin-top: var(--spacing-5x);
}

.spacingButton {
   margin-top: var(--spacing-3x);
}

@media screen and (min-width: 768px) {
   .spacingInput {
      margin-top: var(--spacing-7x);
   }

   .spacingResend {
      margin-top: var(--spacing-2x);
   }

   .spacingButton {
      margin-top: var(--spacing-4x);
   }
}
