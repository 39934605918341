.container {
   display: flex;
   justify-content: center;
   position: absolute;
   inset: 0;
   background-color: rgba(var(--color-contrast-brand-rgb), $alpha: 0.92);
   z-index: 5;
}

.dotsAndHeadingWrapper {
   margin-top: var(--spacing-20x);
   display: flex;
   flex-direction: column;
   gap: var(--spacing-2x);

   @media screen and (min-width: 768px) {
      margin-top: 200px;
      gap: var(--spacing-3x);
   }
}
