.container,
.spacingText,
.spacingOptions,
.option {
   display: flex;
   flex-direction: column;
}

.disabled {
   color: var(--color-neutral-300);
}

.container {
   margin-bottom: var(--spacing-8x);
}
.spacingText {
   gap: var(--spacing-1x);
   margin-bottom: var(--spacing-5x);
}

.spacingOptions {
   width: 100%;
   gap: var(--spacing-4x);
   margin-bottom: var(--spacing-7x);
}

.option {
   gap: var(--spacing-1x);
}

.loading {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 100%;
}

.button {
   margin-bottom: var(--spacing-10x);
}

@media screen and (min-width: 768px) {
   .spacingText {
      margin-bottom: var(--spacing-7x);
   }
   .spacingOptions {
      gap: var(--spacing-3x);
   }
}
