.deniedContainer {
   box-sizing: border-box;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
}

.container {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
   flex: 1;

   @media screen and (min-width: 768px) {
      margin-top: 0px;
   }
}

.content {
   width: 100%;
   max-width: 1440px;
   margin: 0 auto;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;

   @media screen and (min-width: 768px) {
      padding: 0 var(--spacing-4x);
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: var(--spacing-1x);
      grid-row-gap: 0;
   }

   @media screen and (min-width: 1024px) {
      grid-gap: var(--spacing-2x);
      grid-row-gap: 0;
   }
}

.wrapper {
   box-sizing: border-box;
   width: 100%;
   max-width: 450px;
   padding: 0 var(--spacing-2x);
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   margin-top: var(--spacing-10x);
   margin-bottom: 226px;

   @media screen and (min-width: 768px) {
      margin-top: 0px;
      margin-bottom: 0;
      flex-grow: 1;
      grid-column: 2 / 12;
      padding: 0;
   }
}

.textWrapper {
   display: flex;
   flex-direction: column;
   gap: var(--spacing-1x);
   margin-top: var(--spacing-2x);

   @media screen and (min-width: 768px) {
      gap: var(--spacing-4x);
      width: 445px;
   }
}

.text {
   max-width: 270px;
}

.image {
   position: absolute;
   right: 0;
   bottom: 0;
   width: clamp(176px, 45vw, 460px);
}

.brMobile {
   @media screen and (min-width: 768px) {
      display: none;
   }
}
.brDesktop {
   display: none;

   @media screen and (min-width: 768px) {
      display: block;
   }
}
