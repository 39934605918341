.container {
   margin: auto;
}

.wrapper {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   width: 100%;
   max-width: 448px;
}

.content {
   margin-bottom: var(--spacing-2x);
   display: flex;
   flex-direction: column;
   gap: var(--spacing-half);
}

.divider {
   max-width: 448px;
}
