.container,
.goalsContainer,
.goalsSpacing,
.goal,
.oportunitiesContainer,
.oportunitiesSpacing,
.buttonsContainer {
   display: flex;
   flex-direction: column;
}

.container {
   gap: var(--spacing-5x);
}

.goalsContainer,
.goalsSpacing,
.oportunitiesContainer,
.oportunitiesSpacing {
   gap: var(--spacing-3x);
}

.goalsContainer {
   margin-top: var(--spacing-5x);
}

.goal {
   gap: var(--spacing-1x);
}

.disabled {
   color: var(--color-neutral-300);
}

.buttonsContainer {
   gap: var(--spacing-7x);
}

@media screen and (min-width: 768px) {
   .container {
      gap: var(--spacing-6x);
   }

   .goalsSpacing {
      flex-direction: row;
      gap: var(--spacing-5x);
   }

   .goalsContainer {
      margin-top: var(--spacing-8x);
   }

   .goal {
      width: 245px;
   }

   .oportunitiesSpacing {
      max-height: 136px;
      flex-wrap: wrap;
      align-content: flex-start;
      gap: var(--spacing-3x) var(--spacing-5x);
   }

   .buttonsContainer {
      gap: var(--spacing-10x);
   }
}
