.title {
   width: 150%;

   @media screen and (max-width: 912px) {
      width: auto;
   }
}

.spacingSubtitle {
   margin-top: var(--spacing-1x);
}

.spacingRadiogroup {
   margin-top: var(--spacing-5x);

   @media screen and (min-width: 768px) {
      margin-top: var(--spacing-7x);
   }
}

.spacingRadio {
   margin-top: var(--spacing-3x);
}

.spacingInput {
   margin-top: var(--spacing-5x);
}

.spacingButton {
   margin-top: var(--spacing-7x);
}

.spacingButtonInput {
   margin-top: var(--spacing-3x);

   @media screen and (min-width: 768px) {
      margin-top: var(--spacing-4x);
   }
}
