.deniedContainer {
   box-sizing: border-box;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
}

.container {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
   flex: 1;

   @media screen and (min-width: 768px) {
      margin-top: 0px;
   }
}

.content {
   width: 100%;
   max-width: 1440px;
   margin: 0 auto;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;

   @media screen and (min-width: 768px) {
      padding: 0 var(--spacing-4x);
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: var(--spacing-1x);
      grid-row-gap: 0;
   }

   @media screen and (min-width: 1024px) {
      grid-gap: var(--spacing-2x);
      grid-row-gap: 0;
   }
}

.wrapper {
   box-sizing: border-box;
   width: 100%;
   padding: 0 var(--spacing-2x);
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   margin-top: var(--spacing-5x);
   margin-bottom: 226px;

   @media screen and (min-width: 768px) {
      padding: 0;
      margin-bottom: 0;
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      grid-column-gap: var(--spacing-1x);
      grid-row-gap: 0;
      grid-column: 2 / 12;
      margin-top: 0px;
   }

   @media screen and (min-width: 1024px) {
      grid-gap: var(--spacing-2x);
      grid-row-gap: 0;
   }

   .cardsProfileWrapper {
      display: none;
      img {
         width: 328px;
      }

      :first-child {
         align-self: flex-start;
      }

      @media screen and (min-width: 768px) {
         display: flex;
         justify-content: flex-end;
         gap: var(--spacing-2x);
         grid-column: 5 / 11;
         align-self: center;
         align-items: flex-end;
         height: 500px;
         flex-wrap: wrap;
      }
      @media screen and (min-width: 1024px) {
         justify-content: center;
      }

      @media screen and (min-width: 1424px) {
         align-self: flex-end;
         justify-content: space-between;
         margin-bottom: 148px;
      }
   }
}

.wrapperNoGrid {
   box-sizing: border-box;
   width: 100%;
   padding: 0 var(--spacing-2x);
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   margin-top: var(--spacing-5x);
   margin-bottom: 216px;

   @media screen and (min-width: 768px) {
      padding: 0;
      margin-bottom: 172px;
      grid-column-gap: var(--spacing-1x);
      grid-row-gap: 0;
      grid-column: 2 / 12;
   }

   @media screen and (min-width: 1024px) {
      grid-gap: var(--spacing-2x);
      grid-row-gap: 0;
   }
}

.textWrapper {
   max-width: 350px;
   display: flex;
   flex-direction: column;
   gap: var(--spacing-1x);
   margin-top: var(--spacing-2x);
}

.image {
   position: absolute;
   right: 0;
   bottom: 0;
   width: clamp(176px, 45vw, 290px);
}

.brMobile {
   @media screen and (min-width: 768px) {
      display: none;
   }
}

.contentSection {
   @media screen and (min-width: 768px) {
      margin-top: var(--spacing-10x);
      margin-bottom: var(--spacing-2x);
      grid-column: 1 / 5;
   }

   .titleTextWrapper {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-2x);

      @media screen and (min-width: 768px) {
         gap: var(--spacing-3x);
         max-width: 435px;
      }
   }

   .cardsWrapper {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-2x);
      margin-top: var(--spacing-6x);
      margin-bottom: -226px;
      max-width: 320px;

      @media screen and (min-width: 768px) {
         margin-top: var(--spacing-7x);
         margin-bottom: 0;
         max-width: 385px;
      }

      clb-heading-team:nth-of-type(2) {
         margin-top: var(--spacing-3x);
      }
   }

   .card {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: var(--spacing-2x);
   }

   .card:nth-of-type(3) {
      @media screen and (min-width: 768px) {
         width: 108%;
      }
   }

   .iconWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--color-complementary-primary-300);
      min-width: var(--spacing-7x);
      max-width: var(--spacing-7x);
      min-height: var(--spacing-7x);
      max-height: var(--spacing-7x);
      border-radius: var(--border-radius-circular);
      padding: var(--spacing-2x);
   }
}
