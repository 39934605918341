.container {
   margin-bottom: var(--spacing-8x);
}

.container,
.spacingText,
.spacingInput {
   display: flex;
   flex-direction: column;
}

.spacingText {
   gap: var(--spacing-1x);
   margin-bottom: var(--spacing-5x);
}

.spacingInput {
   gap: var(--spacing-3x);
   margin-bottom: var(--spacing-5x);
}

@media screen and (min-width: 768px) {
   .spacingInput {
      margin-bottom: var(--spacing-7x);
   }
}
