.spacingRadioGroup {
   display: flex;
   flex-direction: row;

   margin-top: var(--spacing-5x);
   margin-bottom: var(--spacing-5x);

   @media screen and (min-width: 768px) {
      margin-top: var(--spacing-7x);
      margin-bottom: var(--spacing-5x);
   }

   clb-radio-button {
      padding-right: var(--spacing-3x);
   }
}

.spacingIagree {
   margin-top: var(--spacing-2x);
   margin-bottom: var(--spacing-4x);

   @media screen and (min-width: 768px) {
      margin-top: var(--spacing-3x);
      margin-bottom: var(--spacing-5x);
   }
}
