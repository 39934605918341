.profilePage {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.container {
   display: grid;
   grid-template-columns: repeat(12, 1fr);
   grid-column-gap: var(--spacing-2x);

   @media (min-width: 768px) {
      left: var(--spacing-4x);
      right: var(--spacing-4x);
      width: calc(100% - var(--spacing-8x));
      box-sizing: border-box;
      grid-gap: var(--spacing-1x);
      max-width: 1376px;
      margin: 0 auto;
      display: grid;
   }

   @media screen and (min-width: 1024px) {
      grid-gap: var(--spacing-2x);
   }
}

.containerPersonalData {
   display: grid;
   grid-template-columns: repeat(12, 1fr);
   grid-column-gap: var(--spacing-2x);
}

.header {
   display: grid;
   grid-template-columns: repeat(12, 1fr);
   grid-column-gap: var(--spacing-2x);

   padding-top: var(--spacing-2x);
   padding-left: var(--spacing-half);
   margin-bottom: var(--spacing-5x);

   @media (min-width: 768px) {
      left: var(--spacing-4x);
      right: var(--spacing-4x);
      width: calc(100% - var(--spacing-8x));
      box-sizing: border-box;
      grid-gap: var(--spacing-1x);
      max-width: 1376px;
      margin: 0 auto;
      display: grid;

      padding-top: var(--spacing-2x);
      padding-left: var(--spacing-3x);
      margin-bottom: var(--spacing-7x);
   }

   @media screen and (min-width: 1024px) {
      grid-gap: var(--spacing-2x);
   }
}

.title {
   width: 100%;
   grid-column: 2/12;
}

.tabsWrapper {
   margin-top: var(--spacing-8x);
   min-height: 50vh;

   position: relative;
}

.profileInfoLeftContainer {
   grid-column-start: 1;
   grid-column-end: 6;
   margin-top: var(--spacing-8x);
}

.profileName {
   margin-bottom: 25px;
}

.profileCellphone {
   margin-bottom: 25px;
}

.profileCep {
   margin-bottom: 25px;
}

.profileInfoRightContainer {
   padding: 0;
   width: auto;
   grid-column-start: 7;
   grid-column-end: 12;
   margin-top: var(--spacing-8x);
}

.publicProfile {
   grid-column: 1/-1;
   margin-top: var(--spacing-10x);
   display: flex;
   flex-direction: column;
   gap: var(--spacing-2x);
}

.exitProfile {
   grid-column: 1/-1;
   margin-top: var(--spacing-10x);
   margin-bottom: 84px;
}

.footer {
   width: 100%;
   bottom: 0;
}

@media screen and (max-width: 768px) {
   .container {
      padding: 0;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: var(--spacing-1x);
      margin-left: var(--spacing-2x);
      margin-right: var(--spacing-2x);
   }

   .headerMobile {
      grid-column: 1/-1;
      margin-left: -10px;
      margin-top: 6px;
   }

   .title {
      grid-column: 1/-1;
      margin-top: 0px;
   }

   .tabsWrapper {
      margin-top: 34px;
   }

   .exitProfile {
      grid-column: 1/-1;
      margin-top: var(--spacing-7x);
      margin-bottom: var(--spacing-7x);
   }

   .profileInfoRightContainer {
      padding: 0;
      grid-column: 1/-1;
      margin-top: 25px;
   }

   .publicProfile {
      margin-top: var(--spacing-5x);
   }

   .profileInfoLeftContainer {
      grid-column: 1/-1;
      margin-top: var(--spacing-5x);
   }
}
