.spacingInput {
   margin-top: var(--spacing-5x);
   margin-bottom: var(--spacing-3x);

   @media screen and (min-width: 768px) {
      margin-top: var(--spacing-7x);
      margin-bottom: var(--spacing-4x);
   }
}

.spacingLink {
   margin-top: var(--spacing-2x);
}
