.mb2x {
   margin-bottom: var(--spacing-2x);
}

.mt3x {
   @media screen and (min-width: 768px) {
      margin-top: var(--spacing-3x);
   }
}

.mb2xmt1x {
   margin-bottom: var(--spacing-2x);
   margin-top: var(--spacing-1x);
}

.container {
   box-sizing: border-box;
   width: 100%;
   max-width: 1440px;
   margin: 0 auto;

   @media screen and (min-width: 768px) {
      padding: 0 var(--spacing-4x);
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: var(--spacing-1x);
      grid-row-gap: 0;
   }

   @media screen and (min-width: 1024px) {
      grid-gap: var(--spacing-2x);
      grid-row-gap: 0;
   }

   /* width */
   ::-webkit-scrollbar {
      width: 8px;
   }

   /* Track */
   ::-webkit-scrollbar-track {
      background: var(--color-neutral-background);
      border-radius: 4px;
   }

   /* Handle */
   ::-webkit-scrollbar-thumb {
      background: var(--color-brand-300);
      border-radius: 4px;
   }

   /* Handle on hover */
   ::-webkit-scrollbar-thumb:hover {
      background: var(--color-brand-500);
   }

   a {
      color: var(--color-brand-300);
      cursor: pointer;
      text-decoration: none;
      position: relative;

      &:hover {
         color: var(--color-brand-600);
      }

      &::after {
         content: '';
         display: block;
         position: absolute;
         bottom: 1px;
         right: 0px;
         width: 100%;
         border-top: 1px solid var(--color-brand-300);
      }
   }

   b {
      cursor: pointer;
      text-decoration: none;
      position: relative;

      &::after {
         content: '';
         display: block;
         position: absolute;
         bottom: 0;
         right: 0px;
         width: 100%;
         border-top: 2px solid;
      }
   }
}

.wrapper {
   box-sizing: border-box;
   width: calc(100% - 16px);
   height: 75vh;
   overflow-x: hidden;
   overflow-y: scroll;
   scrollbar-color: var(--color-brand-300) var(--color-neutral-background);
   padding: 0 var(--spacing-2x);
   margin-top: var(--spacing-5x);

   @media screen and (min-width: 768px) {
      margin-top: var(--spacing-10x);
      height: 70vh;
      width: 100%;
      grid-column: 2 / 12;
      padding: 0;
      display: grid;
      grid-template-columns: 80.6% 1fr;
      grid-column-gap: var(--spacing-1x);
      grid-row-gap: 0;
   }

   @media screen and (min-width: 1024px) {
      grid-template-columns: 80.3% 1fr;
      grid-gap: var(--spacing-2x);
      grid-row-gap: 0;
   }
}

.textWrapper {
   display: flex;
   width: 100%;
   flex-direction: column;

   @media screen and (min-width: 768px) {
      grid-column: 1 / 2;
   }
}

.titleWrapper {
   margin-bottom: var(--spacing-5x);
}

.subtitleAndTextWrapper {
   margin-bottom: var(--spacing-3x);
   display: flex;
   flex-direction: column;

   ul {
      margin-left: var(--spacing-3x);
      a {
         color: var(--color-brand-300);
         cursor: pointer;
         text-decoration: underline;

         &::after {
            border-top: none;
         }
      }
   }
}

.tableOneMobile {
   margin: var(--spacing-3x) 0 var(--spacing-1x) 0;
   box-sizing: border-box;
   border-collapse: collapse;

   td {
      width: 50%;
      box-sizing: border-box;
      padding: calc(var(--spacing-2x) - 1px);
      border: 2px solid var(--color-brand-600);
      text-align: start;
   }

   tr:nth-child(odd) {
      background-color: var(--color-brand-100);
   }

   @media screen and (min-width: 768px) {
      width: 100%;
      grid-column: 1 / 2;
      display: none;
   }

   .celHeadingWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
   }

   .celTextWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      ul {
         margin-left: var(--spacing-3x);
      }
   }

   .celTextWrapperMore {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      padding-bottom: 28px;

      a {
         position: absolute;
         bottom: 0;
         right: 0;

         &::after {
            bottom: 3px;
         }
      }

      ul {
         margin-left: var(--spacing-3x);
      }
   }
}

.tableOneDesktop {
   margin: var(--spacing-3x) 0 var(--spacing-2x) 0;
   box-sizing: border-box;
   border-collapse: collapse;
   display: none;

   td,
   th {
      box-sizing: border-box;
      padding: calc(var(--spacing-2x) - 1px);
      border: 2px solid var(--color-brand-600);
      text-align: start;
   }

   th {
      width: 36.3%;
      background-color: var(--color-brand-100);
   }

   @media screen and (min-width: 768px) {
      width: 100%;
      grid-column: 1 / 2;
      display: block;
   }

   .celHeadingWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
   }

   .celTextWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      ul {
         margin-left: var(--spacing-3x);
      }
   }

   .celTextWrapperMore {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      padding-bottom: 28px;

      a {
         position: absolute;
         bottom: 0;
         right: 0;

         &::after {
            bottom: 3px;
         }
      }

      ul {
         margin-left: var(--spacing-3x);
      }
   }
}

.tableTwoMobile {
   margin: var(--spacing-half) 0 0 0;
   box-sizing: border-box;
   border-collapse: collapse;

   td {
      width: 50%;
      box-sizing: border-box;
      padding: calc(var(--spacing-2x) - 1px);
      border: 2px solid var(--color-brand-600);
      text-align: start;
   }

   @media screen and (min-width: 768px) {
      width: 100%;
      grid-column: 1 / 2;
      display: none;
   }

   .celHeadingWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
   }

   .celTextWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      ul {
         margin-left: var(--spacing-3x);
      }
   }

   .celTextWrapperMore {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      padding-bottom: 28px;

      a {
         position: absolute;
         bottom: 0;
         right: 0;

         &::after {
            bottom: 3px;
         }
      }

      ul {
         margin-left: var(--spacing-3x);
      }
   }

   .brandColor {
      background-color: var(--color-brand-100);
      height: 72px;
   }

   .backgroundColor {
      background-color: var(--color-neutral-background);
      height: 104px;
   }
}

.tableTwoDesktop {
   margin: var(--spacing-2x) 0 var(--spacing-2x) 0;
   box-sizing: border-box;
   border-collapse: collapse;
   display: none;

   td,
   th {
      box-sizing: border-box;
      padding: calc(var(--spacing-2x) - 1px);
      border: 2px solid var(--color-brand-600);
      text-align: start;
   }

   th {
      width: 36.3%;
   }

   @media screen and (min-width: 768px) {
      width: 100%;
      grid-column: 1 / 2;
      display: block;
   }

   .celHeadingWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
   }

   .celTextWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      ul {
         margin-left: var(--spacing-3x);
      }
   }

   .celTextWrapperMore {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      padding-bottom: 28px;

      a {
         position: absolute;
         bottom: 0;
         right: 0;

         &::after {
            bottom: 3px;
         }
      }

      ul {
         margin-left: var(--spacing-3x);
      }
   }

   .brandColor {
      background-color: var(--color-brand-100);
      height: 72px;
   }

   .backgroundColor {
      background-color: var(--color-neutral-background);
   }
}
