.cardUsersData {
   width: 100%;
   height: 192px;

   @media screen and (min-width: 952px) {
      width: 332px;
      height: 208px;
   }
}

.titleWrapper {
   height: 60px;
}

.dataWrapper {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-top: 43px;
}

.textWrapperDefault {
   font-family: 'IBM Plex Sans';
   font-style: normal;
   font-weight: 400;
   font-size: 40px;
   line-height: 100%;

   @media screen and (min-width: 768px) {
      font-size: 64px;
   }
}

.textWrapperBlue {
   color: blue;
   font-family: 'IBM Plex Sans';
   font-style: normal;
   font-weight: 400;
   font-size: 40px;
   line-height: 100%;

   @media screen and (min-width: 768px) {
      font-size: 64px;
   }
}
