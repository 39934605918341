.container,
.spacingText,
.spacingInput {
   display: flex;
   flex-direction: column;
}
.container {
   gap: var(--spacing-5x);
   margin-bottom: var(--spacing-8x);
}
.spacingText {
   gap: var(--spacing-1x);
}
.spacingInput {
   gap: var(--spacing-3x);
}

@media screen and (min-width: 768px) {
   .container {
      gap: var(--spacing-7x);
   }
   .spacingInput {
      gap: var(--spacing-4x);
   }
}
