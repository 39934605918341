.modalContent {
   display: flex;
   height: 90%;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   text-align-last: left;
   position: relative;
   
   @media screen and (max-width: 420px) {
      height: 83vh;
   }

   // iPhone 13 and iPhone 13 Pro
   @media only screen 
      and (device-width: 390px) 
      and (device-height: 844px) 
      and (-webkit-device-pixel-ratio: 3) {
         height: 77vh;
   }

   @media screen and (max-width: 375px) {
      height: 80vh;
   }

   @media screen and (max-width: 360px) {
      clb-text {
         br {
            display: none;
         }
      }
   }

   @media screen and (min-width: 768px) {
      text-align-last: center;
      margin-top: var(--spacing-4x);
   }
}

.swiper {
   max-width: 90vw;
   width: max-content;
   @media screen and (min-width: 768px) {
      max-width: 500px;
   }
}
.step {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: flex-start;
   gap: var(--spacing-1x);
   @media screen and (min-width: 768px) {
      align-items: center;
   }
}

.modalButtonWrapper {
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-top: var(--spacing-5x);
   text-align-last: center;
   position: absolute;
   bottom: var(--spacing-3x);
   width: 100vw;
   clb-nav-control,
   clb-button {
      width: 50vw;
   }
   @media screen and (min-width: 768px) {
      justify-content: flex-end;
      flex-direction: row;
      position: initial;
      width: 100%;
      clb-nav-control,
      clb-button {
         width: 100%;
      }
   }
}
