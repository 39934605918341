.container {
   display: flex;
   flex-direction: column;
   justify-content: center;

   @media screen and (max-width: 768px) {
      height: 80vh;
   }

   @media screen and (min-width: 768px) {
      padding: 0 var(--spacing-10x);
   }
}

.header {
   margin-top: var(--spacing-3x);

   display: flex;
   align-items: center;
   justify-content: flex-start;

   @media screen and (min-width: 768px) {
      justify-content: center;
      text-align-last: center;
   }
}

.content {
   margin-top: var(--spacing-5x);

   @media screen and (min-width: 768px) {
      clb-input-textarea {
         width: 448px;
      }
   }
}

.footer {
   margin-top: var(--spacing-3x);

   @media screen and (min-width: 768px) {
      margin-bottom: var(--spacing-7x);
   }
}
