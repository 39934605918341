.helpContainer {
   box-sizing: border-box;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
}

.container {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;

   @media screen and (min-width: 768px) {
      margin-top: 0px;
   }
}

.content {
   box-sizing: border-box;
   width: 100%;
   max-width: 1440px;
   margin: 0 auto;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;

   @media screen and (min-width: 768px) {
      padding: 0 var(--spacing-4x);
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: var(--spacing-1x);
      grid-row-gap: 0;
   }

   @media screen and (min-width: 1024px) {
      grid-gap: var(--spacing-2x);
      grid-row-gap: 0;
   }
}

.wrapper {
   box-sizing: border-box;
   width: 100%;
   padding: 0 var(--spacing-2x);
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   margin-top: var(--spacing-5x);
   margin-bottom: var(--spacing-10x);

   @media screen and (min-width: 768px) {
      margin-bottom: var(--spacing-15x);
      margin-top: var(--spacing-10x);
      display: grid;
      padding: 0;
      grid-template-columns: repeat(10, 1fr);
      column-gap: var(--spacing-2x);
      grid-column: 2 / 12;
   }
}

.headingWrapper {
   display: flex;
   flex-direction: column;
   grid-column: 1 / 5;
}

.accordionGroup {
   display: flex;
   flex-direction: column;
   gap: var(--spacing-5x);
   margin-top: var(--spacing-5x);

   @media screen and (min-width: 768px) {
      margin-top: 0px;
      gap: var(--spacing-6x);
      grid-column: 5 / 11;
   }
}

.accordionHeadingWrapper {
   margin-bottom: var(--spacing-3x);
}
