.modalContent {
   overflow-y: auto;
   display: flex;
   flex-direction: column;
   align-items: center;

   @media screen and (max-width: 768px) {
      padding-top: var(--spacing-7x);
   }

   @media screen and (min-width: 768px) {
      padding-top: var(--spacing-2x);
      width: 560px;
      max-height: 70vh;
   }

   @media screen and (max-width: 375px) {
      clb-text {
         br {
            display: none;
         }
      }
   }
}

.emojiHeadingWrapper {
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;
   gap: var(--spacing-2x);
}

.descriptionWrapper {
   margin-top: -8px;

   @media screen and (min-width: 768px) {
      margin-bottom: 40px;
   }
}

.selectWrapper {
   margin-bottom: 24px;
   width: 100%;

   @media screen and (max-width: 768px) {
      margin-top: 40px;
   }
}

.inputWrapper {
   margin-bottom: 120px;
   height: 194px;
   width: 100%;
}

.modalButtonWrapper {
   display: flex;
   width: 100%;
   justify-content: center;
   align-items: center;

   flex-direction: column;
   clb-button::part(button) {
      min-width: 186px;
   }

   clb-button[type='secondary'] {
      margin-right: 0;
      margin-bottom: var(--spacing-2x);
   }

   @media screen and (min-width: 768px) {
      clb-button[type='secondary'] {
         margin-right: var(--spacing-2x);
         margin-bottom: 0;
      }
      justify-content: flex-end;
      flex-direction: row;
   }
}
