.container {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
}

.content {
   width: 100%;
   max-width: 1440px;
   margin: 0 auto;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   position: relative;

   @media screen and (min-width: 768px) {
      padding: 0 var(--spacing-4x);
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: var(--spacing-1x);
      grid-row-gap: 0;
   }

   @media screen and (min-width: 1024px) {
      grid-gap: var(--spacing-2x);
      grid-row-gap: 0;
   }
}

.cardsWrapper {
   display: flex;
   flex-direction: column;
   justify-content: center;
   gap: var(--spacing-inset-size-sm);

   @media screen and (min-width: 768px) {
      flex-direction: row;
      padding: 0;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      gap: var(--spacing-inset-size-sm);
   }

   .cardWrapper {
      width: 100%;

      @media screen and (min-width: 952px) {
         width: auto;
      }
   }
}

.loading {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 100%;
}

.wrapper {
   padding: 0 var(--spacing-2x);
   width: 100%;
   display: flex;
   flex-direction: column;

   @media screen and (min-width: 768px) {
      grid-column: 2 / 12;
      padding: 0;
   }
}

.chartWrapper {
   display: flex;
   flex-direction: column;
   justify-content: center;
   width: 100%;
   margin-bottom: 96px;
   margin-left: 0;

   @media screen and (min-width: 768px) {
      margin-bottom: 176px;
   }
}

.icon {
   display: flex;
   justify-content: flex-end;
   padding-right: var(--spacing-inset-size-2xs);
}

.title {
   width: 100%;
   margin-bottom: var(--spacing-3x);
   margin-top: var(--spacing-5x);
   display: flex;
   justify-content: flex-start;
   align-items: flex-start;

   @media screen and (min-width: 768px) {
      margin-top: var(--spacing-10x);
   }
}
