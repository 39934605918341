.spacingRadioGroup {
   margin-top: var(--spacing-5x);
   margin-bottom: var(--spacing-7x);

   @media screen and (min-width: 768px) {
      margin-top: var(--spacing-7x);
      margin-bottom: var(--spacing-8x);
   }
}

.spacingRadio {
   margin-top: var(--spacing-3x);
}
