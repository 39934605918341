.containerWrapper {
   width: auto;
   height: auto;
   display: grid;
   grid-template-columns: repeat(12, 1fr);
   grid-column-gap: 16px;

   @media (min-width: 768px) {
      left: 32px;
      right: 32px;
      width: calc(100% - 64px);
      box-sizing: border-box;
      grid-row-gap: 0;
      max-width: 1376px;
      margin: 0 auto;
   }
}

.container {
   width: auto;
   height: auto;
   display: grid;
   background-color: var(--color-neutral-background);

   @media screen and (max-width: 768px) {
      padding: 0 var(--spacing-2x);
   }
}

.heading {
   margin-top: var(--spacing-5x);
   grid-column: 2/12;
}

.text {
   margin-top: var(--spacing-3x);
   grid-column: 2/12;
}

.link {
   margin-top: var(--spacing-2x);
   grid-column: 2/12;
   margin-bottom: var(--spacing-5x);
}

@media screen and (max-width: 768px) {
   .heading {
      grid-column: 1/-1;
      margin-top: 28px;
   }

   .text {
      grid-column: 1/-1;
   }

   .link {
      grid-column: 1/-1;
      margin-bottom: 28px;
   }
}
