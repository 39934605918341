@keyframes loading {
   0% {
      translate: 0 0;
   }
   50% {
      translate: 0 13px;
   }
   100% {
      translate: 0 0;
   }
}
.dotsAnimation {
   display: flex;
   height: 30px;
   justify-content: center;
   gap: var(--spacing-1x);
}

.dot {
   width: 12px;
   height: 12px;
   border-radius: var(--border-radius-circular);
   background-color: var(--color-brand-300);

   @media screen and (min-width: 768px) {
      width: 16px;
      height: 16px;
   }
}
.dot:first-of-type {
   animation: loading 0.5s ease-in-out infinite;
}
.dot:nth-of-type(2) {
   animation: loading 0.5s ease-in-out infinite 0.08s;
}
.dot:nth-of-type(3) {
   animation: loading 0.5s ease-in-out infinite 0.16s;
}
