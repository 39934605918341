.container {
   display: flex;
   flex-direction: row;

   margin-bottom: var(--spacing-5x);

   clb-radio-button {
      padding-right: var(--spacing-3x);
   }
}
