.profilePage {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   height: 100vh;
}

.container {
   display: grid;
   grid-template-columns: repeat(12, 1fr);
   grid-column-gap: 16px;

   @media (min-width: 768px) {
      left: 32px;
      right: 32px;
      width: calc(100% - 64px);
      box-sizing: border-box;
      grid-gap: 8px;
      max-width: 1376px;
      margin: 0 auto;
      display: grid;
   }

   @media screen and (min-width: 1024px) {
      grid-gap: 16px;
   }
}

.header {
   grid-column: 1/-1;
   margin-top: 40px;
}

.title {
   padding: 0;
   max-width: 255px;
   grid-column: 2/-1;
   margin-top: 56px;
}

.profileContainer {
   position: relative;

   grid-column: 1/-1;
   display: grid;
   grid-template-columns: repeat(12, 1fr);
   grid-column-gap: 16px;

   min-height: 30vh;
}

.profileInfoLeftContainer {
   padding: 0;
   width: auto;
   grid-column-start: 2;
   grid-column-end: 6;

   margin-top: var(--spacing-8x);
}

.profileName {
   margin-bottom: 25px;
}

.profileBirthDate {
   margin-bottom: 25px;
}

.profileInfoRightContainer {
   padding: 0;
   width: auto;
   grid-column-start: 7;
   grid-column-end: 11;
   margin-top: var(--spacing-8x);
}

.exitProfile {
   grid-column: 2/-1;
   margin-top: var(--spacing-10x);
}

.footer {
   grid-column: 2/-1;
   width: 100%;
   bottom: 0;

   margin-top: 255px;
}

@media screen and (max-width: 768px) {
   .container {
      padding: 0;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: var(--spacing-1x);
      margin-left: var(--spacing-2x);
      margin-right: var(--spacing-2x);
   }

   .header {
      grid-column: 1/-1;
      margin-left: -10px;
      margin-top: 32px;
   }

   .title {
      grid-column: 1/-1;
      margin-top: var(--spacing-7x);
   }

   .exitProfile {
      grid-column: 1/-1;
      margin-top: var(--spacing-7x);
      bottom: var(--spacing-7x);
   }

   .profileInfoRightContainer {
      padding: 0;
      grid-column: 1/-1;
      margin-top: 25px;
   }

   .profileInfoLeftContainer {
      grid-column: 1/-1;
      margin-top: var(--spacing-5x);
   }

   .footer {
      margin-top: var(--spacing-7x);
   }
}
