.container {
   background-color: var(--color-neutral-background);
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;

   @media screen and (min-width: 1441px) {
      height: 860px;
   }
}

.content {
   width: 100%;
   max-width: 1440px;
   margin: 0 auto;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

   @media screen and (min-width: 1024px) {
      flex-direction: row;
   }
}

.contentSection {
   padding: var(--spacing-10x) var(--spacing-2x);

   @media screen and (min-width: 768px) {
      padding: var(--spacing-10x) var(--spacing-4x);
   }

   @media screen and (min-width: 1024px) {
      padding: 0 var(--spacing-2x) 0 var(--spacing-10x);
      width: 50%;
   }

   @media screen and (min-width: 1441px) {
      margin-right: 720px;
   }

   .titleTextWrapper {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-2x);
      margin-top: var(--spacing-3x);
      max-width: 443px;

      @media screen and (min-width: 1024px) {
         margin-top: var(--spacing-2x);
      }
   }

   .cardsWrapper {
      max-width: 370px;
      display: flex;
      flex-direction: column;
      gap: var(--spacing-2x);
      margin-top: var(--spacing-4x);
   }

   .card {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: var(--spacing-2x);
   }

   .iconWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--color-complementary-primary-300);
      width: var(--spacing-7x);
      height: var(--spacing-7x);
      border-radius: 28px;
      padding: var(--spacing-2x);
   }
}

.imageSection {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   position: relative;

   .image {
      width: 100%;
      max-width: 100%;
      min-height: 480px;
      max-height: 720px;
      object-fit: cover;

      @media screen and (min-width: 1024px) {
         max-height: none;
      }

      @media screen and (min-width: 1441px) {
         width: 100%;
         max-height: 100%;
      }
   }

   @media screen and (min-width: 1024px) {
      width: 50%;
   }

   @media screen and (min-width: 1441px) {
      width: 50%;
      height: 860px;
      position: absolute;
      right: 0;
   }
}
