.spacingText {
   margin-top: var(--spacing-1x);
}

.spacingRadiogroup {
   margin-top: var(--spacing-5x);
   margin-bottom: var(--spacing-10x);

   @media screen and (min-width: 768px) {
      margin-top: var(--spacing-5x);
      margin-bottom: var(--spacing-7x);
   }

   .spacingRadio {
      margin-top: var(--spacing-3x);
   }
}
