.container {
   width: 100%;
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   grid-column-gap: 8px;
   padding-top: var(--spacing-5x);
}

.page {
   grid-column: 1/5;
}

@media screen and (min-width: 768px) {
   .container {
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 8px;
      padding-top: var(--spacing-10x);
   }

   .page {
      grid-column: 2/5;
   }
}

@media screen and (max-width: 760px) {
   .page {
      padding-bottom: 20px;
   }
}
