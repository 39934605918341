.connectionsContainer {
   box-sizing: border-box;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   scrollbar-color: transparent;

   /* width */
   ::-webkit-scrollbar {
      width: 0;
      height: 0;
   }

   /* Track */
   ::-webkit-scrollbar-track {
      background: transparent;
   }

   /* Handle */
   ::-webkit-scrollbar-thumb {
      background: transparent;
   }

   /* Handle on hover */
   ::-webkit-scrollbar-thumb:hover {
      background: transparent;
   }
}

.container {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;

   @media screen and (min-width: 768px) {
      margin-top: 0px;
   }
}

.content {
   box-sizing: border-box;
   width: 100%;
   max-width: 1440px;
   margin: 0 auto;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;

   @media screen and (min-width: 768px) {
      padding: 0 var(--spacing-4x);
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: var(--spacing-1x);
      grid-row-gap: 0;
   }

   @media screen and (min-width: 1024px) {
      grid-gap: var(--spacing-2x);
      grid-row-gap: 0;
   }
}

.wrapper {
   box-sizing: border-box;
   width: 100%;
   padding: 0 var(--spacing-2x);
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   margin-top: var(--spacing-5x);
   margin-bottom: var(--spacing-8x);
   min-height: 100vh;
   overflow-x: hidden;

   @media screen and (min-width: 768px) {
      padding: 0;
      margin-bottom: var(--spacing-20x);
      margin-top: var(--spacing-10x);
      grid-column: 2 / 12;
      overflow-x: visible;
   }

   clb-divider {
      width: 100%;
   }
}

.headingAndTextWrapper {
   display: flex;
   max-width: 564px;
   flex-direction: column;
   gap: var(--spacing-2x);
}

.inputAndFilterWrapper {
   width: 100%;
   display: flex;
   flex-direction: column;
   margin-bottom: var(--spacing-2x);
   margin-top: var(--spacing-4x);

   @media screen and (min-width: 768px) {
      margin-bottom: var(--spacing-3x);
      margin-top: var(--spacing-8x);
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
   }
}

.headingInputWrapper {
   box-sizing: border-box;
   margin-bottom: -8px;

   @media screen and (min-width: 1024px) {
      width: 448px;
   }
}

.buttonFilterWrapper {
   margin-top: var(--spacing-2x);
   width: 100%;
   display: flex;
   justify-content: flex-end;

   @media screen and (min-width: 768px) {
      margin-top: 0px;
      width: auto;
   }
}

.buttonLoadMoreWrapper {
   margin-top: var(--spacing-2x);
   width: 100%;
   display: flex;
   justify-content: center;

   @media screen and (min-width: 768px) {
      margin-top: 0px;
   }
}

.cardsAndLoadmoreWrapper {
   background-color: var(--color-contrast-brand);
   position: relative;
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   flex: 1;
}

.cardsWrapper {
   width: 100%;
   flex-wrap: wrap;
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: var(--spacing-2x);
   margin-top: var(--spacing-2x);
   margin-bottom: var(--spacing-3x);

   @media screen and (min-width: 768px) {
      flex-direction: row;
      margin-top: var(--spacing-3x);
      gap: var(--spacing-3x);
      justify-content: center;
   }
   @media screen and (min-width: 1306px) {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(3, 1fr);
      gap: 1fr;
   }
}

.tagsFilterWrapper {
   margin-top: var(--spacing-3x);
   display: flex !important;
   gap: var(--spacing-1x);
   padding: 0 0 32px 0;
   margin-bottom: -32px;
   width: 100%;
   overflow-x: auto;

   div {
      min-width: max-content;
   }
}

.emptyWrapper {
   max-width: 238px;
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;
   gap: var(--spacing-1x);
   margin-top: 96px;

   @media screen and (min-width: 768px) {
      max-width: 370px;

      .wrapperHeading {
         max-width: 330px;
      }
   }
   
}