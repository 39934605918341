.container,
.spacingText,
.spacingOptions,
.option {
   display: flex;
   flex-direction: column;
}

.spacingText {
   gap: var(--spacing-1x);
   margin-bottom: var(--spacing-5x);
}

.title {
   white-space: nowrap;
}

.spacingOptions {
   gap: var(--spacing-4x);
   margin-bottom: var(--spacing-7x);
}

.option {
   gap: var(--spacing-1x);
}

.button {
   margin-bottom: var(--spacing-10x);
}

@media screen and (min-width: 768px) {
   .spacingText {
      margin-bottom: var(--spacing-7x);
   }

   .spacingOptions {
      display: inline-grid;
      align-items: center;
      gap: var(--spacing-3x);
   }

   .spacingOptionsLoadingFinish {
      grid-template-columns: auto auto;
   }
}

.loading {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 40vh;
}
