.container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   position: absolute;
   inset: 0;
   background-color: rgba(var(--color-contrast-brand-rgb), $alpha: 0.92);
   z-index: 5;
}
