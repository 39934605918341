.mb2x {
   margin-bottom: var(--spacing-2x);
}

.mt1x {
   margin-top: var(--spacing-1x);
}

.mb2xmt1x {
   margin-bottom: var(--spacing-2x);
   margin-top: var(--spacing-1x);
}

.subtitleWrapper {
   margin-top: var(--spacing-1x);
   display: flex;
   margin-left: var(--spacing-half);
   gap: var(--spacing-half);
}

.container {
   box-sizing: border-box;
   width: 100%;
   max-width: 1440px;
   margin: 0 auto;

   @media screen and (min-width: 768px) {
      padding: 0 var(--spacing-4x);
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: var(--spacing-1x);
      grid-row-gap: 0;
   }

   @media screen and (min-width: 1024px) {
      grid-gap: var(--spacing-2x);
      grid-row-gap: 0;
   }

   /* width */
   ::-webkit-scrollbar {
      width: 8px;
   }

   /* Track */
   ::-webkit-scrollbar-track {
      background: var(--color-neutral-background);
      border-radius: 4px;
   }

   /* Handle */
   ::-webkit-scrollbar-thumb {
      background: var(--color-brand-300);
      border-radius: 4px;
   }

   /* Handle on hover */
   ::-webkit-scrollbar-thumb:hover {
      background: var(--color-brand-500);
   }

   a {
      color: var(--color-brand-300);
      cursor: pointer;
      text-decoration: none;
      position: relative;

      &:hover {
         color: var(--color-brand-600);
      }

      &::after {
         content: '';
         display: block;
         position: absolute;
         bottom: 1px;
         right: 0px;
         width: 100%;
         border-top: 1px solid var(--color-brand-300);
      }
   }

   b {
      cursor: pointer;
      text-decoration: none;
      position: relative;

      &::after {
         content: '';
         display: block;
         position: absolute;
         bottom: 0;
         right: 0px;
         width: 100%;
         border-top: 2px solid;
      }
   }
}

.wrapper {
   box-sizing: border-box;
   width: calc(100% - 16px);
   height: 75vh;
   overflow-x: hidden;
   overflow-y: scroll;
   scrollbar-color: var(--color-brand-300) var(--color-neutral-background);
   padding: 0 var(--spacing-2x);
   margin-top: var(--spacing-5x);

   @media screen and (min-width: 768px) {
      margin-top: var(--spacing-10x);
      height: 70vh;
      width: 100%;
      grid-column: 2 / 12;
      padding: 0;
      display: grid;
      grid-template-columns: 80.6% 1fr;
      grid-column-gap: var(--spacing-1x);
      grid-row-gap: 0;
   }

   @media screen and (min-width: 1024px) {
      grid-template-columns: 80.3% 1fr;
      grid-gap: var(--spacing-2x);
      grid-row-gap: 0;
   }
}

.textWrapper {
   display: flex;
   width: 100%;
   flex-direction: column;

   @media screen and (min-width: 768px) {
      grid-column: 1 / 2;
   }
}

.titleWrapper {
   margin-bottom: var(--spacing-5x);
}

.subtitleAndTextWrapper {
   margin-bottom: var(--spacing-3x);
   display: flex;
   flex-direction: column;

   ul {
      margin-left: var(--spacing-3x);
      a {
         color: var(--color-brand-300);
         cursor: pointer;
         text-decoration: underline;

         &::after {
            border-top: none;
         }
      }
   }
}
