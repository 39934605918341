.modalContent {
   display: flex;
   height: 100%;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   text-align-last: center;

   @media screen and (max-width: 360px) {
      clb-text {
         br {
            display: none;
         }
      }
   }
   @media screen and (min-width: 768px) {
      margin-top: var(--spacing-4x);
   }
}

.emojiHeadingWrapper {
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;
   gap: var(--spacing-2x);
}

.descriptionWrapper {
   margin-top: -8px;

   @media screen and (min-width: 768px) {
      margin-bottom: 91px;
   }
}

.modalButtonWrapper {
   display: flex;
   width: 100%;
   justify-content: center;
   align-items: center;

   @media screen and (max-width: 768px) {
      margin-top: var(--spacing-5x);
   }

   flex-direction: column;
   clb-button::part(button) {
      min-width: 186px;
   }

   clb-button[type='secondary'] {
      margin-right: 0;
      margin-bottom: var(--spacing-2x);
   }

   @media screen and (min-width: 768px) {
      clb-button[type='secondary'] {
         margin-right: var(--spacing-2x);
         margin-bottom: 0;
      }
      justify-content: flex-end;
      flex-direction: row;
   }
}
