.container {
   overflow-y: auto;
   padding-top: var(--spacing-7x);
   display: flex;
   flex-direction: column;
   align-items: center;

   @media screen and (min-width: 768px) {
      padding-top: var(--spacing-2x);
      width: 712px;
      max-height: 70vh;
   }
}

.titleTextWrapper {
   max-width: 493px;
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;
   gap: var(--spacing-1x);

   :first-child {
      margin-bottom: var(--spacing-1x);
   }
}

.form {
   width: calc(100% - 8px);
   margin-top: var(--spacing-5x);
   margin-bottom: var(--spacing-2x);
   display: flex;
   flex-direction: column;
   gap: var(--spacing-3x);

   @media screen and (min-width: 768px) {
      margin-top: var(--spacing-3x);
   }
}

.nameCelWrapper {
   display: flex;
   flex-direction: column;
   gap: var(--spacing-3x);

   @media screen and (min-width: 768px) {
      flex-direction: row;
   }
}

.checkboxWrapper {
   align-self: flex-start;
   max-width: 340px;
   display: flex;
   gap: var(--spacing-1x);
   margin-bottom: var(--spacing-3x);
   margin-left: 4px;

   clb-checkbox {
      margin-top: var(--spacing-half);
   }

   a {
      color: var(--color-brand-300);
   }
}

.buttonWrapper {
   width: 100%;
   display: flex;
   justify-content: flex-end;
   margin-bottom: var(--spacing-5x);

   @media screen and (min-width: 768px) {
      margin-bottom: 0;
   }
}
