.container {
   display: flex;
   flex-direction: column;
}

.userInfoWrapper {
   display: flex;
   gap: var(--spacing-6x);

   @media screen and (max-width: 1026px) {
      flex-direction: column;
      gap: var(--spacing-2x);
   }
}

.leftContainer {
   min-width: var(--spacing-25x);
   gap: var(--spacing-2x);

   .celular {
      margin-top: var(--spacing-2x);
   }
}

.rightContainer {
   .birthDate {
      margin-top: var(--spacing-2x);
   }

   .birthDatePdv {
      margin-top: 0;
   }

   @media screen and (max-width: 1026px) {
      margin-top: 0;
   }
}

.headerDesktop {
   display: none;

   @media screen and (min-width: 1026px) {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .tagsWrapperDesktop {
         display: flex;
         flex-direction: row;
         justify-content: flex-start;
         align-items: center;
         gap: var(--spacing-2x);
      }

      .buttonWrapperDesktop {
         display: flex;
         flex-direction: row;
         justify-content: flex-end;
         align-items: center;
         gap: var(--spacing-2x);
      }
   }
}

.tagsWrapper {
   display: flex;
   gap: var(--spacing-2x);

   @media screen and (min-width: 1026px) {
      display: none;
   }

   @media screen and (max-width: 410px) {
      flex-wrap: wrap;
   }
}

.buttonWrapper {
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: var(--spacing-2x);

   margin-top: var(--spacing-5x);

   @media screen and (min-width: 1026px) {
      display: none;
   }
}
