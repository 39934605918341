.carousel {
   box-sizing: border-box;
   display: flex;
   flex-direction: column;
   width: 100%;
   height: 462px;
   overflow: hidden;
   padding: 0;
   margin: 0;
   justify-content: space-between;
   align-items: center;
   margin-top: var(--spacing-5x);
   margin-bottom: var(--spacing-2x);

   @media screen and (min-width: 768px) {
      display: none;
   }
}

.row {
   position: relative;
}

.container {
   box-sizing: border-box;
   position: absolute;
   width: 344px;
   height: 422px;
   overflow: hidden;
   background: #fff;
   border-radius: 8px;
}

.container > img {
   width: 100%;
   height: 100%;
}

.carouselControls {
   display: flex;
   width: 100%;
   height: 18px;
   max-width: 1392px;
   justify-content: flex-end;
   align-items: center;
   margin: 24px auto 0 auto;
}

.dotsWrapper {
   display: flex;
   align-items: center;
   gap: 8px;
   margin: 0 32px;
}

.dot {
   width: 8px;
   height: 8px;
   border-radius: 4px;
   background-color: var(--color-neutral-200);
   transition: all 250ms ease;
}

.currentDot {
   width: 16px;
   height: 16px;
   border-radius: 8px;
   background-color: var(--color-brand-300);
   transition: all 250ms ease;
}
