.adminContainer {
   box-sizing: border-box;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   scrollbar-color: transparent;

   /* width */
   ::-webkit-scrollbar {
      width: 0;
      height: 0;
   }

   /* Track */
   ::-webkit-scrollbar-track {
      background: transparent;
   }

   /* Handle */
   ::-webkit-scrollbar-thumb {
      background: transparent;
   }

   /* Handle on hover */
   ::-webkit-scrollbar-thumb:hover {
      background: transparent;
   }
}

.content {
   box-sizing: border-box;
   width: 100%;
   max-width: 1440px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;

   @media screen and (min-width: 768px) {
      padding: 0 var(--spacing-4x);
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: var(--spacing-1x);
      grid-row-gap: 0;
   }

   @media screen and (min-width: 1024px) {
      grid-gap: var(--spacing-2x);
      grid-row-gap: 0;
   }
}

.wrapper {
   box-sizing: border-box;
   width: 100%;
   padding: 0 var(--spacing-2x);
   margin-top: var(--spacing-5x);
   margin-bottom: var(--spacing-8x);
   overflow-x: hidden;

   @media screen and (min-width: 1024px) {
      padding: 0;
      margin-bottom: 0;
      grid-column: 2 / 12;
      overflow-x: visible;
   }

   @media screen and (min-width: 768px) {
      padding: 0;
      margin-bottom: var(--spacing-8x);
      grid-column: 2 / 12;
      overflow-x: visible;
   }

   clb-divider {
      width: 100%;
   }
}

.inputWrapper {
   width: 100%;
   display: flex;
   flex-direction: column;
   margin-bottom: var(--spacing-2x);
   margin-top: var(--spacing-4x);

   @media screen and (min-width: 768px) {
      margin-bottom: var(--spacing-3x);
      margin-top: var(--spacing-8x);
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
   }
}

.headingInputWrapper {
   box-sizing: border-box;
   margin-bottom: -8px;

   @media screen and (min-width: 1024px) {
      width: 448px;
   }
}

.mainContentWrapper {
   position: relative;
   padding: 0;
   width: 100%;
   display: flex;
   justify-content: center;

   @media screen and (min-width: 768px) {
      justify-content: center;
   }
}

.userCardWrapper {
   margin-top: var(--spacing-2x);

   @media screen and (min-width: 768px) {
      margin-top: 55px;
   }
}

.emojiCardWrapper {
   max-width: 325px;
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;
   gap: var(--spacing-1x);
   margin-top: 129px;

   @media screen and (min-width: 768px) {
      max-width: 370px;

      .wrapperHeading {
         max-width: 337px;
      }
   }
}
