.spacingAddress {
   display: flex;
   flex-direction: row;

   align-items: center;
   justify-content: space-between;

   margin-top: var(--spacing-5x);
   margin-bottom: var(--spacing-3x);

   @media screen and (min-width: 768px) {
      margin-top: var(--spacing-7x);
      margin-bottom: var(--spacing-4x);
   }
}
