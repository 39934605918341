.container {
   width: 100%;
   max-width: 1440px;
   margin: 0 auto;

   display: flex;
   flex: 1;
   flex-direction: column;

   height: -webkit-fill-available;

   @media screen and (min-width: 768px) {
      padding: 0 32px;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 8px;
      height: 100vh;
   }

   @media screen and (min-width: 1024px) {
      grid-column-gap: 16px;
   }
}

.content {
   display: flex;
   flex: 1;
   flex-direction: column;

   @media screen and (min-width: 768px) {
      grid-column: 1/7;
      padding: 0;

      .infoBanner {
         display: none;
      }
   }
}

.navigationWrapper {
   z-index: 1;
   top: 0;

   @media screen and (max-width: 760px) {
      position: fixed;
      width: 100%;
   }
}

.childrens {
   display: flex;
   flex: 1;

   padding: 0 16px;

   @media screen and (min-width: 768px) {
      padding: 0;
   }

   @media screen and (max-width: 760px) {
      margin-top: 70px;
   }
}

.banner {
   display: none;

   @media screen and (min-width: 768px) {
      display: block;
      position: fixed;
      right: 0;
      top: 0;

      width: 50%;
      height: 100vh;

      background-color: var(--color-complementary-secondary-300);
      background-image: url('./../../assets/banner.svg');
      background-position: center;

      .infoBanner {
         position: absolute;
         bottom: 0;
      }
   }
}

.infoBanner {
   width: 100%;
   height: 56px;
   background-color: var(--color-complementary-primary-300);

   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;

   padding: 0 16px;

   @media screen and (min-width: 768px) {
      padding: 0 32px;
      height: 72px;
   }
}

.contentWords {
   width: 100px;
   height: 100px;
   background-color: black;
}
