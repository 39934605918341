.modalContent {
   display: flex;
   height: 100%;
   flex-direction: column;
   justify-content: center;
   align-items: center;

   @media screen and (min-width: 768px) {
      margin-top: var(--spacing-4x);
   }
}

.emojiHeadingWrapper {
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: var(--spacing-2x);
   text-align: center !important;
}

.modalRadiosWrapper {
   display: flex;
   gap: 28px;
   margin-top: var(--spacing-4x);
   margin-bottom: var(--spacing-7x);

   @media screen and (min-width: 768px) {
      margin-bottom: var(--spacing-8x);
   }
}

.modalButtonWrapper {
   display: flex;
   width: 100%;
   justify-content: center;
   align-items: center;

   @media screen and (min-width: 768px) {
      justify-content: flex-end;
   }
}
