.container {
   background-color: var(--color-complementary-primary-300);
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
}

.content {
   width: 100%;
   max-width: 1440px;
   margin: 0 auto;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   position: relative;

   @media screen and (min-width: 768px) {
      display: grid;
      padding: 0 var(--spacing-4x);
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: var(--spacing-1x);
      grid-row-gap: 0;
   }

   @media screen and (min-width: 1024px) {
      grid-gap: var(--spacing-2x);
      grid-row-gap: 0;
   }
}

.wrapper {
   padding: 0 var(--spacing-2x);
   margin-top: var(--spacing-10x);
   margin-bottom: var(--spacing-10x);
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

   @media screen and (min-width: 768px) {
      grid-column: 2 / 12;
      margin-bottom: var(--spacing-15x);
      padding: 0;
   }
}

.mobileImageWrapper {
   width: 100%;
   display: flex;
   justify-content: flex-end;
   align-items: center;
   margin-bottom: var(--spacing-15x);

   @media screen and (min-width: 768px) {
      display: none;
   }
}

.title {
   width: 100%;
   margin-bottom: var(--spacing-5x);
   display: flex;
   justify-content: flex-start;

   @media screen and (min-width: 768px) {
      margin-bottom: 230px;
   }
}

.textWrapper {
   max-width: 384px;
}

.imageDesktop {
   display: none;

   @media screen and (min-width: 768px) {
      display: block;
   }
}

.textImageWrapper {
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: flex-end;
}
