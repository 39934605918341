.container {
   width: 100%;
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   grid-column-gap: 8px;

   align-items: center;
}

.page {
   grid-column: 1/5;
}

@media screen and (min-width: 768px) {
   .container {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 8px;
   }

   .page {
      grid-column: 2/5;
   }
}
