.container {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
}

.content {
   width: 100%;
   max-width: 1440px;
   margin: 0 auto;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   position: relative;

   @media screen and (min-width: 768px) {
      padding: 0 var(--spacing-4x);
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: var(--spacing-1x);
      grid-row-gap: 0;
   }

   @media screen and (min-width: 1024px) {
      grid-gap: var(--spacing-2x);
      grid-row-gap: 0;
   }
}

.wrapper {
   padding: 0 var(--spacing-2x);
   margin: var(--spacing-10x) 0;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

   @media screen and (min-width: 768px) {
      grid-column: 2 / 12;
      padding: 0;
   }
}

.title {
   width: 100%;
   margin-bottom: var(--spacing-10x);
   display: flex;
   justify-content: flex-start;

   @media screen and (min-width: 768px) {
      margin-bottom: 240px;
   }
}

.cardsWrapper {
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: var(--spacing-8x);

   @media screen and (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      gap: 20px;
   }
}

.card {
   max-width: 330px;

   .headingWrapper {
      margin-bottom: var(--spacing-1x);
   }

   .image {
      width: var(--spacing-8x);
      height: var(--spacing-8x);
      padding: var(--spacing-2x);
      border-radius: var(--spacing-2x);
      background-color: var(--color-complementary-secondary-300);
      margin-bottom: var(--spacing-3x);

      @media screen and (min-width: 768px) {
         padding: 19.5px;
         margin-bottom: var(--spacing-5x);
         width: 72px;
         height: 72px;
      }
   }
}
