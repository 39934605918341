$icons: (
   'check':
      '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99999 15.172L18.485 6.68606C18.8755 6.29553 19.5086 6.2954 19.8993 6.68577C20.2901 7.07633 20.2902 7.7098 19.8995 8.10049L10.0767 17.9233C10.0343 17.9657 9.96566 17.9657 9.92331 17.9233L4.34299 12.343C3.95252 11.9525 3.95252 11.3195 4.34299 10.929C4.73345 10.5385 5.36652 10.5385 5.75699 10.929L9.99999 15.172Z" fill="white"/></svg>',
);

.checkbox {
   $root: &;
   display: flex;
   align-items: flex-start;
   font-family: var(--font-family-base);
   font-size: var(--font-size-default);
   color: var(--color-neutral-600);
   line-height: var(--line-height-default);

   cursor: pointer;
   pointer-events: auto;

   input {
      margin-right: var(--spacing-1x);

      $input: &;
      width: 24px;
      height: 24px;
      cursor: pointer;
      pointer-events: auto;
      border-radius: var(--border-radius-sm);

      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;

      appearance: none;
      position: relative;

      &:focus:not(:focus-visible) {
         outline: transparent;
      }

      &:focus-visible {
         outline: 2px solid var(--color-contrast-focused);
         outline-offset: 2px;
      }

      &:before {
         position: absolute;
         content: '';
         display: block;
         width: 16px;
         height: 16px;
         box-sizing: border-box;
      }

      &:after {
         position: absolute;
         content: '';
         display: block;
         width: 16px;
         height: 16px;
         border: var(--border-width-default) solid var(--color-neutral-300);
         border-radius: inherit;
         box-sizing: border-box;
      }

      &:hover {
         &:before {
            background-color: var(--color-brand-100);
            border-radius: var(--border-radius-sm);
         }

         &:after {
            border: var(--border-width-default) solid var(--color-brand-300);
         }
      }

      &:checked {
         &:before {
            background-color: var(--color-brand-300);
            border-radius: var(--border-radius-sm);
         }

         &:after {
            border: var(--border-width-default) solid var(--color-brand-300);
            background-image: url('data:image/svg+xml;utf-8,' + map-get(
                  $icons,
                  'check'
               ));
            background-size: contain;
            color: var(--color-contrast-brand);
         }

         & ~ span {
            font-weight: var(--font-weight-semibold);
         }
      }

      &:disabled {
         &:before {
            background-color: var(--color-neutral-300) !important;
            opacity: var(--opacity-level-3) !important;
         }

         &:after {
            color: var(--color-neutral-300);
            border: var(--border-width-default) solid var(--color-neutral-300) !important;
            opacity: var(--opacity-level-3) !important;
         }

         & ~ span {
            font-weight: var(--font-weight-regular);
            color: var(--color-neutral-300);

            &:checked {
               font-weight: var(--font-weight-semibold);
            }
         }
      }
   }

   a {
      color: var(--color-brand-300);
   }
}
