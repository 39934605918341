.container {
   width: 100%;
   overflow-y: auto;
   position: relative;

   @media screen and (min-width: 768px) {
      padding-left: var(--spacing-2x);
   }
}

.titleAndCheckboxWrapper {
   display: flex;
   flex-direction: column;
   gap: var(--spacing-3x);
   margin: var(--spacing-5x) 0;

   @media screen and (min-width: 768px) {
      margin-top: var(--spacing-6x);
   }
}

.checkboxWrapperFlex {
   display: flex;
   flex-direction: column;
   gap: var(--spacing-3x);
   flex-wrap: wrap;

   @media screen and (min-width: 768px) {
      flex-direction: row;
      gap: var(--spacing-4x);
   }
}

.checkboxWrapperFlexColumn {
   display: flex;
   flex-direction: column;
   gap: var(--spacing-3x);
   @media screen and (min-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: var(--spacing-4x);
   }
}

.checkbox {
   min-width: 170px;
}

.buttonsWrapper {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: var(--spacing-2x);
   margin-top: var(--spacing-3x);
   margin-bottom: var(--spacing-10x);
   flex-wrap: wrap;

   @media screen and (min-width: 768px) {
      margin-bottom: var(--spacing-2x);
      flex-direction: row;
      justify-content: flex-end;
      position: absolute;
      bottom: 0;
      right: var(--spacing-2x);
   }
}
