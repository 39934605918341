:root:has(clb-modal[isopen]) {
   overflow: hidden;
}

:root:has(clb-super-drawer[visible]) {
   overflow: hidden;
}

#root {
   height: -webkit-fill-available;
}

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

body {
   -webkit-font-smoothing: antialiased;
   font-size: 1rem;
   height: -webkit-fill-available;
}

html {
   height: -webkit-fill-available;
}
