.container {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: var(--spacing-5x);
   margin-bottom: -1px;
   position: relative;

   @media screen and (min-width: 768px) {
      margin-top: -1px;
   }

   @media screen and (min-width: 1441px) {
      height: 721px;
   }
}

.content {
   width: 100%;
   max-width: 1440px;
   margin: 0 auto;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

   @media screen and (min-width: 768px) {
      padding: 0 var(--spacing-4x);
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: var(--spacing-1x);
      grid-row-gap: 0;
   }

   @media screen and (min-width: 1024px) {
      grid-column-gap: var(--spacing-2x);
      grid-row-gap: 0;
   }
}

.wrapper {
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   min-height: 508px;

   @media screen and (min-width: 768px) {
      display: grid;
      grid-template-columns: repeat(11, 1fr);
      column-gap: var(--spacing-2x);
      grid-column: 2 / 13;
   }
}

.title {
   width: 286px;
   margin: 0 var(--spacing-2x) auto var(--spacing-2x);
   z-index: 1;

   @media screen and (min-width: 768px) {
      width: 420px;
      margin: 0 0 var(--spacing-10x) 0;
      grid-column: 1 / 5;
      flex-wrap: nowrap;
   }
}

.button {
   display: block;
   margin-top: var(--spacing-3x);

   @media screen and (min-width: 768px) {
      display: none;
   }
}

.image {
   width: 100%;
   max-width: 500px;
   margin-top: -60px;
   margin-bottom: -1px;
   align-self: flex-end;

   @media screen and (min-width: 768px) {
      opacity: 0;
      width: calc(100% + var(--spacing-4x));
      max-width: none;
      margin-top: 0px;
      grid-column: 5 / 12;
   }

   @media screen and (min-width: 1182px) {
      opacity: 1;
      margin-bottom: -2px;
   }

   @media screen and (min-width: 1441px) {
      width: 100%;
      max-width: 830px;
      position: absolute;
      right: 0;
      bottom: 0;
      top: 0;
   }
}

.imageAbsolute {
   display: none;

   @media screen and (min-width: 768px) {
      display: block;
      position: absolute;
      width: clamp(500px, 65vw, 617px);
      right: 0;
      bottom: -2px;
   }

   @media screen and (min-width: 1182px) {
      width: clamp(617px, 55%, 830px);
      display: none;
   }

   @media screen and (min-width: 1441px) {
      width: 100%;
      max-width: 830px;
   }
}

.heroFooter {
   width: 100%;
   max-width: 1440px;
   margin: 0 auto;
   display: flex;
   padding: 0 var(--spacing-2x);
   justify-content: space-between;
   align-items: center;
   position: absolute;
   bottom: var(--spacing-4x);

   @media screen and (min-width: 768px) {
      position: relative;
      grid-column: 2 / 12;
      padding: 0;
      margin-top: -48px;
   }

   @media screen and (min-width: 1441px) {
      margin-bottom: -210px;
   }
}

.logos {
   display: flex;
   justify-content: center;
   gap: var(--spacing-1x);

   @media screen and (min-width: 768px) {
      gap: var(--spacing-3x);

      img:last-child {
         width: 90px;
      }

      img:first-child {
         width: 120px;
      }
   }
}
